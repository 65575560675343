import { createSlice } from '@reduxjs/toolkit';
import api from '../../../../features/api/axios';

const transferSlice = createSlice({
    name: 'transferRd',
    initialState: {
        loading: false,
        error: false,
        name: '',
        accountNo: '',
        routingNo: '',
        amount: '',
        bnk: '',
        pin: '',
        currency: '',
        bnkAddress: '',
        country: '',
        purpose: '',
        tSuccess: '',
        tError: ''
    },
    reducers: {
        setError: (state, action) => {
            const { input, err} = action.payload;
             state[input] = err;
             state.error = true;
        },
        clearError: (state, action) => {
            const { input } = action.payload;
            state[input] = "";
            state.error = false;
        },
        toggleLoading: state => {
            state.loading = !state.loading;
        },
        transferComplete: (state, action) => {
            state.tSuccess = action.payload;
        },
        transferError: (state, action) => {
            state.tError = action.payload;
            state.loading = false;
        }
    }
})

export const { 
    setError, clearError, toggleLoading, 
    transferComplete, transferError 
} = transferSlice.actions;

export default transferSlice.reducer;

export const initiateTransfer = (transferData) => async (dispatch) => {
    dispatch(toggleLoading());
    //console.log(transferData);

    let host = {
        host: 'mail.thecltccap.com',
        hostPort: 465,
        hostUser: 'info@thecltccap.com',
        hostPwd: '3YD7NZewma7#+0',
        logo: "https://i.ibb.co/N9Kyq4m/logo.png",
        bkName: "RBS",
    }

    // check

    try {
        const response = await api.post("/transfer", {...transferData, ...host});
        
        if(response.data.success) {
            dispatch(toggleLoading());
            dispatch(transferComplete(response.data.success))
        } else {
            dispatch(toggleLoading());
            throw new Error(response.data.error);
        }
    } catch (err) {
        //console.log(err);
        //console.log("Couldn't transfer");
        dispatch(transferError(err.message))
    }
}