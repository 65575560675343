import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTop from '../../features/page-top/PageTop';
import { Typography } from '@material-ui/core';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';
import ImageText from '../../features/image-text/ImageText';
import { mapImg, speakImg } from '../../images';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import styles from './contact.module.css'


const contactAddress = () => {
    return(
        <section>
            <h1> Come visit </h1>
            <Typography variant="h4"> The Head Office </Typography>
            <br />
            <Typography variant="h6">
                36 St Andrew Square, Edinburgh, United Kingdom, EH2 2YB
            </Typography>
        </section>
    )
}

const contactSpeak = () => {
    return(
        <section>
            <br /><br />
            <h1> Get In Touch </h1>
            <Typography variant="h4"> The Head Office </Typography>
            <br />
            <Typography variant="h6">
                <p> <EmailIcon color="primary" className={styles.icon} /><strong> Support </strong> support@thecltccap.com </p>
                <p> <EmailIcon color="secondary" className={styles.icon} /><strong> Info </strong> info@thecltccap.com </p>
                <p> <PhoneIcon className={styles.icon} />  +44 (745) 907 8952 </p>
            </Typography>
        </section>
    )
}



const Contact = () => {
    document.title="How to Reach Us"

    return(
        <section>

            <SiteMap sliceIndex={1} />
            <br />
            
            <PageTop
                header=""
                buttonText="Coronavirus Help"
                buttonLink="/current-account"
                bgImage = "contact"
                displayBtn = {false}
                />

            <ContainerMaxWidth>
            <ImageText
                PageText={contactAddress}
                image={mapImg}
            />
            <hr />
            <ImageText
                PageText={contactSpeak}
                image={speakImg}
                floatImageRight={true}
            />


            </ContainerMaxWidth>

        </section>
    )
}

export default Contact;